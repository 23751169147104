import { getUrlFromPath } from 'helpers/strings';
import React from 'react';
import { Route } from 'react-router-dom';
import { AccessConfig } from '../constants/accesses';

type CMSRouteProps = {
  path: string;
  component: any;
  exact?: boolean;
  strict?: boolean;
  accessConfig?: AccessConfig;
};

export default function route<P = void>(defaultProps: CMSRouteProps) {
  function getLink(params?: P) {
    return getUrlFromPath(defaultProps.path, params);
  }

  return class CMSRoute extends React.Component<CMSRouteProps> {
    static path = defaultProps.path;
    static exact = defaultProps.exact;
    static strict = defaultProps.strict;
    static defaultProps = defaultProps;
    static getLink = getLink;

    render() {
      const { component: Component, ...props } = this.props;

      if (defaultProps.accessConfig) {
        const noAdminGroups = !window.adminType;

        const notAvailable =
          noAdminGroups ||
          !window.isAdmin ||
          !defaultProps.accessConfig?.accessList.some(
            (role) => window.adminType === role
          );

        if (defaultProps.accessConfig?.invert) {
          if (!notAvailable) {
            return null;
          }
        } else {
          if (notAvailable) {
            return null;
          }
        }
      }

      return <Route component={Component} {...props} />;
    }
  };
}
