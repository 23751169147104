import React from 'react';

import route from 'hooks/route';
import { superAdminConfig } from 'constants/accesses';

export const ToSRootRoute = route({
  path: '/tos',
  component: React.lazy(() => import('pages/tos/index')),
  accessConfig: superAdminConfig,
});

export const ToSFormRoute = route<{ locale: string, accessType?: string }>({
  path: '/tos/:locale/:accessType?',
  component: React.lazy(() => import('pages/tos/tos-form')),
  accessConfig: superAdminConfig,
  exact: true,
});
