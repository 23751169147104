import { Action } from 'react-fetching-library';

export enum CurrentUserEndpoint {
  currentUser = '/api/current_user.json',
  signIn = '/users/sign_in.json',
  signOut = '/users/sign_out',
  adminVerify = '/api/admin/users/admins/token_verifications',
  resetPassword = '/api/users/password_instructions',
}

export function getCurrentUserAction(): Action {
  return {
    method: 'GET',
    endpoint: CurrentUserEndpoint.currentUser,
  };
}

export type SignInParams = {
  user: {
    email: string;
    password: string;
  };
};

export function signInAction(params: SignInParams): Action {
  return {
    method: 'POST',
    endpoint: CurrentUserEndpoint.signIn,
    body: params,
  };
}

export function adminVerifyAction(token: string): Action {
  return {
    method: 'POST',
    endpoint: CurrentUserEndpoint.adminVerify,
    body: {
      token: token,
    },
  };
}

export function signOutAction(): Action {
  return {
    method: 'DELETE',
    endpoint: CurrentUserEndpoint.signOut,
  };
}

export const resetPassword = (email: string): Action => ({
  method: 'POST',
  endpoint: CurrentUserEndpoint.resetPassword,
  body: {
    email: email,
  },
});
