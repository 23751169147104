import route from '../hooks/route';
import { clientDeskAccessConfig } from '../constants/accesses';
import { NewslettersType } from 'api/admin/newsletters/newsletters.types';
import React from 'react';

export const NewslettersRoute = route<{ type: NewslettersType }>({
  path: '/newsletters/:type',
  component: React.lazy(() => import('pages/newsletters')),
  accessConfig: clientDeskAccessConfig,
});
