import route from 'hooks/route';
import {
  liveAdminConfig,
  onlyLiveAdminConfig,
  newsRoomAccessConfig,
} from 'constants/accesses';
import React from 'react';

const _liveAccessConf = {
  accessList: [
    ...liveAdminConfig.accessList,
    ...newsRoomAccessConfig.accessList,
  ],
  invert: false,
} as typeof liveAdminConfig;

export const RootContentRoute = route({
  path: '/content',
  component: React.lazy(() => import('pages/content/videos')),
});

export const VideosRoute = route({
  path: '/content/videos',
  component: React.lazy(() => import('pages/content/videos')),
});

export const VideosAllRoute = route({
  path: '/content/videos/all',
  component: React.lazy(() => import('pages/content/videos/videos-all')),
  exact: true,
});

export const VideosTrendingRoute = route({
  path: '/content/videos/trending',
  component: React.lazy(() => import('pages/content/videos/videos-trending')),
  exact: true,
});

interface IVideoViewRouteProps {
  id: string | number;
}

export const VideosViewRoute = route<IVideoViewRouteProps>({
  path: '/content/videos/all/:id',
  component: React.lazy(() => import('pages/content/videos/videos-view-item')),
  accessConfig: {
    ...onlyLiveAdminConfig,
    accessList: [
      ...onlyLiveAdminConfig.accessList,
      'seo_admin',
      'news_room',
      'client_desk',
      'output_editor',
    ],
  },
  exact: true,
});

export const VideosViewDownloadsRoute = route<IVideoViewRouteProps>({
  path: '/content/videos/all/:id/downloads',
  accessConfig: liveAdminConfig,
  component: React.lazy(
    () => import('pages/content/videos/videos-view-item/downloads')
  ),
});

export const VideosViewTranslationsRoute = route<IVideoViewRouteProps>({
  path: '/content/videos/all/:id/translations',
  component: React.lazy(
    () => import('pages/content/videos/videos-view-item/translations')
  ),
  accessConfig: {
    ...liveAdminConfig,
    accessList: [
      ...liveAdminConfig.accessList,
      'news_room',
      'translator',
      'output_editor',
    ],
  },
});

export const VideosViewTagsRoute = route<{
  id: string | number;
  locale: string;
}>({
  path: '/content/videos/all/:id/tags/:locale',
  component: React.lazy(
    () => import('pages/content/videos/videos-view-item/tags')
  ),
  accessConfig: {
    ...liveAdminConfig,
    accessList: [
      ...liveAdminConfig.accessList,
      'news_room',
      'output_editor',
      'seo_admin',
    ],
  },
});

// ---- live events ----
export const LiveEventsRoute = route({
  path: '/content/live-events',
  component: React.lazy(() => import('pages/content/live-events')),
  accessConfig: {
    ..._liveAccessConf,
    accessList: [..._liveAccessConf.accessList, 'seo_admin'],
  },
});

export const LiveEventsAllRoute = route({
  path: '/content/live-events/all',
  component: React.lazy(() => import('pages/content/live-events/events-all')),
  accessConfig: {
    ..._liveAccessConf,
    accessList: [..._liveAccessConf.accessList, 'seo_admin'],
  },
  exact: true,
});

export const LiveEventsHistoryRoute = route({
  path: '/content/live-events/history',
  component: React.lazy(
    () => import('pages/content/live-events/events-history')
  ),
  exact: true,
  accessConfig: _liveAccessConf,
});

export const LiveSmartLinksRoute = route({
  path: '/content/live-events/smart-links',
  component: React.lazy(() => import('pages/content/live-events/smart-links')),
  exact: true,
  accessConfig: liveAdminConfig,
});

export const TranscodersMonitorServiceRoute = route({
  path: '/content/live-events/monitor',
  component: React.lazy(
    () => import('pages/content/live-events/transcoders-monitor')
  ),
});

export const LiveEventViewRoute = route<{ slug: string }>({
  path: '/content/live-event/:slug',
  component: React.lazy(
    () => import('pages/content/live-events/lives-view-item')
  ),
  exact: true,
  accessConfig: {
    ..._liveAccessConf,
    accessList: [..._liveAccessConf.accessList, 'seo_admin'],
  },
});

export const LiveEventTranslationsRoute = route<{ id: string }>({
  path: '/content/live-event/:id/translations',
  component: React.lazy(
    () => import('pages/content/live-events/lives-view-item/translations')
  ),
  exact: true,
  accessConfig: _liveAccessConf,
});

export const LiveEventTagsRoute = route<{
  type: string;
  id: string;
  locale: string;
}>({
  path: '/content/:type/:id/tags/:locale',
  component: React.lazy(
    () => import('pages/content/videos/videos-view-item/tags')
  ),
  exact: true,
  accessConfig: {
    ..._liveAccessConf,
    accessList: [..._liveAccessConf.accessList, 'seo_admin'],
  },
});

//tags
export const TagsHistoryRoute = route<{ locale: string }>({
  path: '/content/tags/:id/history',
  component: React.lazy(() => import('pages/content/tags/history')),
  exact: true,
  accessConfig: {
    ...newsRoomAccessConfig,
    accessList: [...newsRoomAccessConfig.accessList, 'seo_admin'],
  },
});

export const TagsRoute = route<{ locale: string }>({
  path: '/content/tags/:locale',
  component: React.lazy(() => import('pages/content/tags')),
  accessConfig: {
    ...newsRoomAccessConfig,
    accessList: [...newsRoomAccessConfig.accessList, 'seo_admin'],
  },
});

export const TagsOnDashboardHistoryRoute = route<{ locale: string }>({
  path: '/content/tags-on-dashboard/:id/history',
  component: React.lazy(() => import('pages/content/tags/history')),
  exact: true,
  accessConfig: {
    ...newsRoomAccessConfig,
    accessList: [...newsRoomAccessConfig.accessList, 'seo_admin'],
  },
});

export const TagsOnDashboardRoute = route<{ locale: string }>({
  path: '/content/tags-on-dashboard/:locale',
  component: React.lazy(() => import('pages/content/tags-on-dashboard')),
  accessConfig: {
    ...newsRoomAccessConfig,
    accessList: [...newsRoomAccessConfig.accessList, 'seo_admin'],
  },
});

export const FtpVideosRoute = route({
  path: '/content/ftp-videos',
  component: React.lazy(() => import('pages/content/ftp-videos')),
  exact: true,
});
