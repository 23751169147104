import {
  AllEventsProvider,
  EventsProvider,
  LiveEventsPastProvider,
  LiveEventsProvider,
} from 'api/admin/content/live/events';
import { TagsProvider } from 'api/admin/content/tags';
import { VideosSearchProvider } from 'api/admin/content/video_projects/search';
import { CurrentUserProvider } from 'api/current_user';
import fetchingClient from 'api/fetching-client';
import { LocalesProvider } from 'api/locales';
import { TimezonesProvider } from 'api/timezones';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ClientContextProvider } from 'react-fetching-library';
import App from './App';
import * as serviceWorker from './serviceWorker';
import CombineProviders from 'hooks/combine-providers';

if (typeof window !== 'undefined') {
  (window as any).Buffer = (window as any).Buffer || require('buffer').Buffer;
}

ReactDOM.render(
  <React.StrictMode>
    <Suspense
      fallback={
        <div className="loader" style={{ display: 'block' }}>
          <div className="loader-inner">
            <span className="loader__step-1" />
            <span className="loader__step-2" />
            <span className="loader__step-3" />
          </div>
        </div>
      }
    >
      <ClientContextProvider client={fetchingClient}>
        <CombineProviders
          providers={[
            CurrentUserProvider,
            LocalesProvider,
            TimezonesProvider,
            [VideosSearchProvider, { initFetch: false }],
            [LiveEventsProvider, { initFetch: false }],
            [AllEventsProvider, { initFetch: false }],
            [EventsProvider, { initFetch: false }],
            [LiveEventsPastProvider, { initFetch: false }],
            [TagsProvider, { initFetch: true }],
          ]}
        >
          <App />
        </CombineProviders>
      </ClientContextProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
