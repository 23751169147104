import React from 'react';
import config from 'config';

interface ILoaderProps {
  style?: React.CSSProperties;
}

export default function Loader(props: ILoaderProps) {
  if (config.SWITCH) {
    return (
      <div className="loader" {...props}>
        <div className="spinner-eclipse">
          <span></span>
        </div>
      </div>
    );
  } else {
    return (
      <div className="loader j-loader" {...props}>
        <div className="loader-inner">
          <span className="loader__step-1" />
          <span className="loader__step-2" />
          <span className="loader__step-3" />
        </div>
      </div>
    );
  }
}
