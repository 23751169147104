import {
  Action,
  useParameterizedQuery,
  useQuery,
} from 'react-fetching-library';

type TApiOptions = {
  action: (...args: any[]) => Action;
  defaultPayload: any;
  preparePayload?: (state: any) => void;
};

export default class Api {
  action: (id?: number | string) => Action;
  defaultPayload: any;
  payload: any;

  preparePayload = (payload: any) => payload;

  constructor(options: TApiOptions) {
    this.action = options.action;
    this.defaultPayload = options.defaultPayload;

    if (options.preparePayload) this.preparePayload = options.preparePayload;
  }

  prepare(state: any) {
    this.payload = state.payload;
    return {
      ...state,
      payload: this.preparePayload.call(
        this,
        state.payload || this.defaultPayload
      ),
    };
  }

  useQuery(id?: number | string) {
    const state = useQuery(this.action(id));
    return this.prepare(state);
  }

  useParameterizedQuery() {
    const state = useParameterizedQuery(this.action);
    return this.prepare(state);
  }
}
