import React, { useContext } from 'react';
import 'react-slidedown/lib/slidedown.css';
import NotifyAppContext from './notifications-context';
import NotificationsItem from './notifications-item';

const Notifications: React.FC = () => {
  const { list } = useContext(NotifyAppContext);
  return (
    <div
      className="notification-messages"
      style={{
        display: list.length === 0 ? 'none' : 'block',
        position: 'fixed',
        maxHeight: '67px',
      }}
    >
      {list.map((item) => (
        <NotificationsItem key={item.id} {...item} />
      ))}
    </div>
  );
};
export default Notifications;
