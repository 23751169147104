import available, { AdminGroups } from 'hooks/check-access';

export type AccessConfig = { accessList: AdminGroups[]; invert: boolean };

export const newsRoomAccessConfig: AccessConfig = {
  accessList: ['output_editor', 'super_admin', 'live_admin'],
  invert: false,
};

export const newsRoomSuperAdminConfig: AccessConfig = {
  accessList: ['output_editor', 'super_admin'],
  invert: false,
};

export const newsRoomSuperAdminOutputEditorConfig: AccessConfig = {
  accessList: ['super_admin', 'output_editor'],
  invert: false,
};

export const newsRoomSuperAdminOutputEditorClientDeskConfig: AccessConfig = {
  accessList: ['super_admin', 'output_editor', 'client_desk'],
  invert: false,
};

export const newsRoomTranslatorAccessConfig: AccessConfig = {
  accessList: ['translator', 'output_editor', 'super_admin'],
  invert: false,
};

export const liveTranslatorAccessConfig: AccessConfig = {
  accessList: ['translator', 'live_admin', 'output_editor', 'super_admin'],
  invert: false,
};

export const clientDeskAccessConfig: AccessConfig = {
  accessList: ['client_desk', 'super_admin'],
  invert: false,
};

export const seoAccessConfig: AccessConfig = {
  accessList: ['seo_admin', 'super_admin'],
  invert: false,
};

export const clientDeskConfig: AccessConfig = {
  accessList: ['client_desk', 'super_admin'],
  invert: false,
};

export const liveAdminConfig: AccessConfig = {
  accessList: ['live_admin', 'super_admin', 'client_desk', 'translator'],
  invert: false,
};

export const onlyLiveAdminConfig: AccessConfig = {
  accessList: ['live_admin', 'super_admin'],
  invert: false,
};

export const newsRoomLiveAdminConfig: AccessConfig = {
  accessList: [
    'live_admin',
    'super_admin',
    'client_desk',
    'output_editor',
    'translator',
  ],
  invert: false,
};

export const urlsShareConfig: AccessConfig = {
  accessList: ['super_admin', 'client_desk'],
  invert: false,
};

export const clientDeskAdminConfig: AccessConfig = {
  accessList: ['client_desk', 'live_admin', 'super_admin'],
  invert: false,
};

export const notForClientDeskConfig: AccessConfig = {
  accessList: ['client_desk', 'seo_admin', 'output_editor', 'translator'],
  invert: true,
};

export const exportVideoStatisticsConfig: AccessConfig = {
  accessList: ['client_desk', 'super_admin'],
  invert: false,
};

export const videoItemConfig: AccessConfig = {
  accessList: ['client_desk', 'seo_admin', 'output_editor', 'super_admin'],
  invert: false,
};

export const userProfileConfig: AccessConfig = {
  accessList: ['client_desk', 'super_admin'],
  invert: false,
};

export const onlyTranslatorConfig: AccessConfig = {
  accessList: ['translator', 'super_admin'],
  invert: false,
};

export const notForTranslatorConfig: AccessConfig = {
  accessList: ['translator', 'seo_admin'],
  invert: true,
};

export const notForTranslatorLiveConfig: AccessConfig = {
  accessList: ['translator', 'seo_admin', 'output_editor'],
  invert: true,
};

export const superAdminConfig: AccessConfig = {
  accessList: ['super_admin'],
  invert: false,
};

export const settingsConfig: AccessConfig = {
  accessList: ['super_admin', 'seo_admin'],
  invert: false,
};

export const OutputEditorAdminsConfig: AccessConfig = {
  accessList: ['output_editor', 'super_admin'],
  invert: false,
};

export const notSeoAndClient: AccessConfig = {
  accessList: ['client_desk', 'seo_admin'],
  invert: true,
};

export const notOutputEditor: AccessConfig = {
  accessList: ['output_editor', 'translator'],
  invert: true,
};

export const seoMetaTagsConfig: AccessConfig = {
  accessList: ['live_admin', 'client_desk', 'seo_admin', 'super_admin'],
  invert: false,
};

export const seoMetaTagsEditConfig: AccessConfig = {
  accessList: ['live_admin', 'seo_admin', 'super_admin'],
  invert: false,
};

export const planningSectionConfig: AccessConfig = {
  accessList: ['output_editor', 'live_admin', 'client_desk', 'super_admin'],
  invert: false,
};

export const SeoMetaTags = available(
  seoMetaTagsConfig.accessList,
  seoMetaTagsConfig.invert
);
export const SeoMetaTagsEdit = available(
  seoMetaTagsEditConfig.accessList,
  seoMetaTagsEditConfig.invert
);
export const NewsRoomElement = available(
  newsRoomAccessConfig.accessList,
  newsRoomAccessConfig.invert
);
export const NewsRoomOutputEditorElement = available(
  newsRoomSuperAdminOutputEditorConfig.accessList,
  newsRoomSuperAdminOutputEditorConfig.invert
);
export const NewsRoomSuperAdminOutputEditorClientDeskElement = available(
  newsRoomSuperAdminOutputEditorClientDeskConfig.accessList,
  newsRoomSuperAdminOutputEditorClientDeskConfig.invert
);
export const NewsRoomTranslatorElement = available(
  newsRoomTranslatorAccessConfig.accessList,
  newsRoomTranslatorAccessConfig.invert
);
export const ClientDeskElement = available(
  clientDeskAccessConfig.accessList,
  clientDeskAccessConfig.invert
);
export const SeoElement = available(
  seoAccessConfig.accessList,
  seoAccessConfig.invert
);
export const OnlyClientDeskElement = available(
  clientDeskConfig.accessList,
  clientDeskConfig.invert
);
export const LiveAdminElement = available(
  liveAdminConfig.accessList,
  liveAdminConfig.invert
);
export const LiveTranslatorElement = available(
  liveTranslatorAccessConfig.accessList,
  liveTranslatorAccessConfig.invert
);
export const OnlyLiveAdminElement = available(
  onlyLiveAdminConfig.accessList,
  onlyLiveAdminConfig.invert
);
export const NewsRoomLiveAdminElement = available(
  newsRoomLiveAdminConfig.accessList,
  newsRoomLiveAdminConfig.invert
);
export const UrlsShare = available(
  urlsShareConfig.accessList,
  urlsShareConfig.invert
);
export const NewsRoomSuperAdminElement = available(
  newsRoomSuperAdminConfig.accessList,
  newsRoomSuperAdminConfig.invert
);
export const NewsRoomSuperAdminOutputEditorElement = available(
  newsRoomSuperAdminOutputEditorConfig.accessList,
  newsRoomSuperAdminOutputEditorConfig.invert
);
export const ClientDeskLiveAdminElement = available(
  clientDeskAdminConfig.accessList,
  clientDeskAdminConfig.invert
);
export const NotForClientDesk = available(
  notForClientDeskConfig.accessList,
  notForClientDeskConfig.invert
);
export const ExportVideoStatistics = available(
  exportVideoStatisticsConfig.accessList,
  exportVideoStatisticsConfig.invert
);
export const UserProfileElement = available(
  userProfileConfig.accessList,
  userProfileConfig.invert
);
export const OnlyTranslatorElement = available(
  onlyTranslatorConfig.accessList,
  onlyTranslatorConfig.invert
);
export const NotTranslatorElement = available(
  notForTranslatorConfig.accessList,
  notForTranslatorConfig.invert
);
export const NotTranslatorLiveElement = available(
  notForTranslatorLiveConfig.accessList,
  notForTranslatorLiveConfig.invert
);
export const SuperAdminElement = available(
  superAdminConfig.accessList,
  superAdminConfig.invert
);
export const SettingsElement = available(
  settingsConfig.accessList,
  settingsConfig.invert
);
export const OutputEditorElement = available(
  OutputEditorAdminsConfig.accessList,
  OutputEditorAdminsConfig.invert
);
export const TranslationsLink = available(
  notSeoAndClient.accessList,
  notSeoAndClient.invert
);
export const NotOutputEditorLinks = available(
  notOutputEditor.accessList,
  notOutputEditor.invert
);
export const VideoElement = available(
  videoItemConfig.accessList,
  videoItemConfig.invert
);
export const PlanningSection = available(
  planningSectionConfig.accessList,
  planningSectionConfig.invert
);
