import route from 'hooks/route';
import { seoAccessConfig, superAdminConfig } from 'constants/accesses';
import React from 'react';

export const RootSettingsRoute = route({
  path: '/settings',
  component: React.lazy(() => import('pages/settings')),
});

export const GeneralSettingsRoute = route({
  path: '/settings/general',
  component: React.lazy(() => import('pages/settings/general')),
  accessConfig: superAdminConfig,
  exact: true,
});

export const TranscodersSettingsRoute = route({
  exact: true,
  path: '/settings/transcoders',
  component: React.lazy(() => import('pages/settings/transcoders')),
  accessConfig: superAdminConfig,
});

export const TargetsSettingsRoute = route({
  path: '/settings/transcoders/targets',
  component: React.lazy(
    () => import('pages/settings/transcoders/transcoder-targets')
  ),
  accessConfig: superAdminConfig,
});

export const EmailServiceRoute = route({
  exact: true,
  path: '/settings/email-service',
  component: React.lazy(() => import('pages/settings/email-service')),
  accessConfig: superAdminConfig,
});

export const CountriesSettingsRoute = route({
  path: '/settings/countries',
  component: React.lazy(() => import('pages/settings/countries')),
  accessConfig: superAdminConfig,
});

export const SettingsContentRoute = route({
  path: '/settings/countries/content',
  component: React.lazy(() => import('pages/settings/countries/content')),
  accessConfig: superAdminConfig,
});

export const SettingsBusinessRoutes = route({
  path: '/settings/countries/business',
  component: React.lazy(() => import('pages/settings/countries/business')),
  accessConfig: superAdminConfig,
});

export const SettingsSeoMetaTagsRoute = route({
  path: '/settings/seo-tags',
  component: React.lazy(() => import('pages/settings/seo-meta-tags')),
  accessConfig: seoAccessConfig,
});

export const SettingsReleaseNotesRoute = route({
  path: '/settings/release-notes',
  component: React.lazy(() => import('pages/settings/release-notes')),
  accessConfig: superAdminConfig,
});

export const SettingsWowzaRoute = route({
  path: '/settings/wowza-servers',
  component: React.lazy(() => import('pages/settings/servers')),
  accessConfig: superAdminConfig,
});
