import { useMutation, useParameterizedQuery } from 'react-fetching-library';
import createQueryContext from 'react-fetching-library-context';
import * as actions from './events.actions';
import {
  getEventBookingsAction,
  getLanguageData,
  getTranscodersData,
  INotificationParams,
  IReportDelayParams,
  ISlugParams,
} from './events.actions';
import {
  AllEventsResponse,
  BookingsType,
  FollowingType,
  ILiveEvent,
  IUpdateLiveEvent,
  LanguagesType,
  LiveEventsResponse,
  ReCreateLiveEventType,
  TargetsStatistics,
  TargetsType,
  TranscodersType,
} from './events.types';
import { useSearchParams } from 'hooks/use-search-params';

export const { useQueryContext: useEvents, Provider: EventsProvider } =
  createQueryContext<ILiveEvent[]>(actions.getEvents);

export const { useQueryContext: useAllEvents, Provider: AllEventsProvider } =
  createQueryContext<AllEventsResponse>(actions.getAllEvents);

export const { useQueryContext: useLiveEvents, Provider: LiveEventsProvider } =
  createQueryContext<LiveEventsResponse>(actions.getActiveEvents);

export const {
  useQueryContext: useLiveEventsPast,
  Provider: LiveEventsPastProvider,
} = createQueryContext<LiveEventsResponse>(actions.getPastEvents);

export const { useQuery: useLanguageData } =
  createQueryContext<LanguagesType[]>(getLanguageData);

export const { useQuery: useTranscodersData } =
  createQueryContext<TranscodersType[]>(getTranscodersData);

export function useActiveEvents() {
  return useParameterizedQuery(actions.getActiveEvents);
}

export function useEventsByParams() {
  return useParameterizedQuery(actions.getEventsByParams);
}

export const {
  useQueryContext: useEventViewContext,
  Provider: EventViewProvider,
} = createQueryContext<ILiveEvent, string>(actions.getEventAction);

export function useGetEvent() {
  return useParameterizedQuery(actions.getEventAction);
}

export function useGetEventChannelData() {
  return useParameterizedQuery(actions.getEventChannelDataAction);
}

export function useCreateEvent() {
  const liveView = useEventViewContext();
  const createEvent = useMutation(actions.createEventAction);

  return async function (params: { event: IUpdateLiveEvent }) {
    const result = await createEvent.mutate(params);
    try {
      liveView.payload && (await liveView.query(result.payload.slug));
    } catch (err) {}
    return result;
  };
}

export function useUpdateEvent() {
  const updateEvent = useMutation(actions.updateEventAction);
  const updateEventStatus = useMutation(actions.updateEventStatusAction);
  const liveEventsList = useLiveEvents();
  const liveView = useEventViewContext();

  const [searchParams] = useSearchParams();

  return async function (params: { slug: string; event: IUpdateLiveEvent }) {
    const isGoLive: boolean = params.event.status === 'going_live';
    const dataEvent = isGoLive
      ? { ...params.event, status: 'live' }
      : params.event;

    const result = await updateEvent.mutate({
      slug: params.slug,
      event: dataEvent,
    });

    if (isGoLive) {
      await updateEventStatus.mutate({
        slug: params.slug,
        status: 'live',
      });
    }

    if (result?.payload?.status !== 'failed') {
      try {
        liveView.payload && liveView.query(params.slug);
        liveEventsList.payload && liveEventsList.query(searchParams);
      } catch (err) {}
    }

    return result;
  };
}

export function useReCreateEvent() {
  const reCreateEvent = useMutation(actions.reCreateEventAction);
  return async function (params: {
    slug: string;
    params: ReCreateLiveEventType;
  }) {
    const result = await reCreateEvent.mutate(params);
    return result;
  };
}

export function useUpdateEventStatus() {
  const updateEventStatus = useMutation(actions.updateEventStatusAction);
  const liveEventsList = useLiveEvents();
  const liveView = useEventViewContext();

  return async function (params: { slug: string; status: string }) {
    const result = await updateEventStatus.mutate(params);
    try {
      liveView.payload && (await liveView.query(params.slug));
      liveEventsList.payload && (await liveEventsList.query({}));
    } catch (err) {}
    return result;
  };
}

export function useSendEventPushNotification() {
  const send = useMutation(actions.sendPushNotificationAction);

  return async function (slug: string) {
    const result = await send.mutate(slug);
    return result;
  };
}

export const {
  useQueryContext: useLiveEventsBookings,
  Provider: LiveEventsBookingsProvider,
} = createQueryContext<BookingsType[], ISlugParams>(getEventBookingsAction);

export function useGetFollowingEvent() {
  return useParameterizedQuery<FollowingType[], ISlugParams>(
    actions.getEventFollowingAction
  );
}

export function useGetTargetsEvent() {
  return useParameterizedQuery<TargetsType[], ISlugParams>(
    actions.getEventTargetsAction
  );
}

export function useGetTargetsPastEvent() {
  return useParameterizedQuery<TargetsType[], ISlugParams>(
    actions.getEventTargetsPastAction
  );
}

export function useGetTargetsStatisticsEvent() {
  return useParameterizedQuery<TargetsStatistics, ISlugParams>(
    actions.getTargetsStatisticsAction
  );
}

export function useSendCustomNotificationEvent() {
  const send = useMutation(actions.sendCustomNotificationsAction);

  return async function (params: INotificationParams) {
    const result = await send.mutate(params);
    return result;
  };
}

export function useReportDelayEvent() {
  const send = useMutation(actions.reportLiveEventDelayAction);

  return async function (params: IReportDelayParams) {
    const result = await send.mutate(params);
    return result;
  };
}

export function useSendNotifyClientDesk(isList = false) {
  const send = useMutation(actions.sendNotifyClientDeskAction);
  const live = useEventViewContext();
  const getEvent = useGetEvent();
  const liveEventsList = useLiveEvents();

  return async function (params: ISlugParams) {
    const result = await send.mutate(params);
    if (isList) {
      const liveItem: ILiveEvent = (await getEvent.query(params.slug)).payload;
      const paylaod = liveEventsList.payload;
      const index = (paylaod?.events || []).findIndex(
        i => i.slug === params.slug
      );

      if (index !== -1 && paylaod?.events[index] && liveItem) {
        paylaod.events[index] = {
          ...paylaod.events[index],
          last_notified_at: liveItem.last_notified_at,
          notify_cd_counter: liveItem.notify_cd_counter,
        };

        liveEventsList.setPayload({
          ...paylaod,
          events: [...paylaod.events],
        });
      }
    } else {
      await live.query(params.slug);
    }
    return result;
  };
}

export function useSendLinksNotifyClientDesk() {
  const send = useMutation(actions.sendLinksNotifyClientDeskAction);
  const live = useEventViewContext();

  return async function (params: ISlugParams) {
    const result = await send.mutate(params);
    await live.query(params.slug);
    return result;
  };
}
