import { useSearchParams } from 'hooks/use-search-params';
import qs from 'qs';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Action } from 'react-fetching-library';
import createQueryContext from 'react-fetching-library-context';
import { NotifyAppContext } from 'components/notifications';
import { IVideoProject } from '../video_projects.types';

interface ISearchResponse {
  count: number;
  videos: IVideoProject[];
}

export type BulkSelectType =
  | 'none'
  | 'external_name_in'
  | 'external_name_not_in'
  | 'all';

export const {
  useQuery: useVideosSearchQuery,
  useQueryContext: useVideosSearchContext,
  Provider: VideosSearchProvider,
} = createQueryContext<ISearchResponse>(getVideosSearch);

export function getVideosSearch(params: any = {}): Action {
  return {
    endpoint:
      '/api/admin/content/video_projects/search' +
      qs.stringify(params, { addQueryPrefix: true }),
    method: 'GET',
  };
}

const per_page = 15;

export function useVideosFeed() {
  const [showOnlySelected, toggleShowOnlySelected] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [selectedType, setSelectedType] = useState<BulkSelectType>('none');
  const [params, setParams] = useSearchParams();
  const [pageCount, setPageCount] = useState(Number(params.page) || 1);
  const search = useVideosSearchContext();
  const [feed, setFeed] = useState<IVideoProject[]>([]);
  const [loadMoreFeed, setLoadMoreFeed] = useState<IVideoProject[]>([]);
  let videosFoundList = search.payload?.videos;
  const videosFoundCounter = search.payload?.count || 0;
  const [pagPageUsedList, updatePagPageUsedList] = useState<number[]>([]);
  const [loadMoreAction, setLoadMoreActionStatus] = useState(false);
  const isUsedSelected = useRef(false);

  const { showNotifyHandle } = useContext(NotifyAppContext);

  const load = () => {
    search.query({ ...params, per_page, page: pageCount }).catch(err => {
      console.log(err);
      showNotifyHandle({
        type: 'error',
        title: 'Something went wrong',
      });
    });
  };

  useEffect(() => {
    Object.keys(params).length > 0 && load();
  }, [params]);

  useEffect(() => {
    setParams({
      ...params,
      per_page: String(per_page),
      page: String(pageCount),
    });
  }, [pageCount]);

  function updFeed() {
    updatePagPageUsedList(prev => {
      prev = [...prev];
      prev.push(pageCount);
      return prev;
    });
    setFeed([...feed, ...(videosFoundList || [])]);
  }

  const toggleSelectedAll = useCallback(
    (status: BulkSelectType) => {
      setSelectedType(status);
      (status === 'none' || status === 'all') && setSelected([]);
    },
    [selected]
  );

  const selectVideos = useCallback((id: string) => {
    setSelected(prev => {
      let list = [...prev];
      const index = list.findIndex(_id => _id === id);
      index !== -1 ? list.splice(index, 1) : list.push(id);
      isUsedSelected.current = true;
      return list;
    });
  }, []);

  const selectCurrentPage = useCallback(() => {
    videosFoundList &&
      setSelected(prev => {
        prev = [...prev];
        videosFoundList!.forEach(video => prev.push(video.external_name));
        return prev;
      });
    setSelectedType('external_name_in');
  }, [videosFoundList]);

  const changePageHandler = useCallback((pageNumber: number = 1) => {
    setPageCount(prevNumb => {
      if (pageNumber === prevNumb) load();
      return pageNumber;
    });
  }, []);

  useEffect(() => {
    if (!search.error && videosFoundList) {
      if (loadMoreAction) {
        setLoadMoreFeed(prev => prev.concat(videosFoundList!));
        updFeed();
        setLoadMoreActionStatus(false);
      } else if (
        !pagPageUsedList.includes(pageCount) &&
        videosFoundList.length > 0
      ) {
        updFeed();
        setLoadMoreFeed(videosFoundList);
      } else {
        setLoadMoreFeed(videosFoundList);
        toggleShowOnlySelected(false);
        if (showOnlySelected && selected.length) {
          setPageCount(1);
        }
      }
    }
  }, [videosFoundList]);

  useEffect(() => {
    !selected.length && toggleShowOnlySelected(false);
    isUsedSelected.current &&
      setSelectedType(_prev => {
        isUsedSelected.current = false;
        if (_prev === 'all') {
          return 'external_name_not_in';
        } else if (_prev === 'none') {
          return 'external_name_in';
        } else if (_prev === 'external_name_in') {
          if (selected.length === 0) return 'none';
          return _prev;
        } else if (_prev === 'external_name_not_in') {
          if (selected.length === 0) return 'all';
          return _prev;
        } else {
          return 'all';
        }
      });
  }, [selected]);

  const loadMoreHandle = useCallback(() => {
    setPageCount(prev => ++prev);
    setLoadMoreActionStatus(true);
  }, []);

  const clearSelected = useCallback(() => {
    setPageCount(1);
    toggleShowOnlySelected(false);
  }, []);

  const updateVideo = useCallback(
    (video: IVideoProject) => {
      if (!videosFoundList) return null;
      const feedVideoIndex = feed.findIndex(el => el.id === video.id);
      const currVideoIndex = videosFoundList.findIndex(
        el => el.id === video.id
      );
      const loadMoreVideoIndex = loadMoreFeed.findIndex(
        el => el.id === video.id
      );

      if (currVideoIndex !== -1) {
        videosFoundList[currVideoIndex] = video;
      }

      if (loadMoreVideoIndex !== -1) {
        let updLoadMoreFeed = [...loadMoreFeed];
        updLoadMoreFeed[loadMoreVideoIndex] = video;
        setLoadMoreFeed(updLoadMoreFeed);
      }

      if (feedVideoIndex !== -1) {
        let updFeed = [...feed];
        updFeed[feedVideoIndex] = video;
        setFeed(updFeed);
      }
    },
    [feed, videosFoundList, loadMoreFeed]
  );

  return {
    feed,
    loading: search.loading && !loadMoreAction,
    loadMoreLoading: search.loading && loadMoreAction,
    loadMoreHandle,
    loadMoreFeed,
    showLoadMore: (videosFoundList || []).length < videosFoundCounter,
    videosFoundList: videosFoundList || [],
    updateVideo,
    changePageHandler,
    videosFoundCounter,
    currentVideoPage: pageCount,
    perPage: per_page,
    showOnlySelected,
    toggleShowOnlySelected,
    pagPageUsedList,
    selected,
    selectVideos,
    toggleSelectedAll,
    selectedType,
    selectCurrentPage,
    clearSelected,
  };
}
