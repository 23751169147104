import React from 'react';
// import config from 'config';

// const { MAIN_HOST, BRAND_NAME } = config;

export default function Footer() {
  return (
    <footer className="fsfooter j-footer" style={{ margin: 0 }}>
      <div className="fsfooter__blocks">
        {/* <div className="fsfooter__block">
          <span className="fsfooter__copy">© {BRAND_NAME} GmbH</span>
        </div>
        <div className="fsfooter__block">
          <span className="fsfooter__problems">
            Something went wrong?&nbsp;
            <a href={`mailto:digital@${MAIN_HOST}`} className="fsfooter__link">
              digital@{config.MAIN_HOST}
            </a>
          </span>
        </div> */}
      </div>
    </footer>
  );
}
