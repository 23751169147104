import { Action } from 'react-fetching-library';
import {
  ITag,
  ITagRequest,
  RemoveTagParam,
  ReplaceAndDestroyTagParam,
  ToggleDashboardParams,
  ToggleTagActiveParams,
  UpdatePositionParams,
} from './tags.types';

export function getTagsAction(search: any): Action {
  return {
    endpoint: '/api/admin/content/tags',
    method: 'GET',
    config: {
      search,
    },
  };
}
export function getDashboardTagsAction(search: any): Action {
  return {
    endpoint: '/api/admin/content/tags/dashboard',
    method: 'GET',
    config: {
      search,
    },
  };
}

export function getTagAction(id: number): Action {
  return {
    endpoint: `/api/admin/content/tags/${id}`,
    method: 'GET',
  };
}

export function updatePositionAction({
  id,
  ...params
}: UpdatePositionParams): Action {
  return {
    endpoint: `/api/admin/content/tags/${id}/positions`,
    method: 'PATCH',
    body: params,
  };
}

export function toggleDashboardAction({
  id,
  ...params
}: ToggleDashboardParams): Action {
  return {
    endpoint: `/api/admin/content/tags/${id}/dashboard`,
    method: 'PATCH',
    body: params,
  };
}

export function toggleTagActiveAction({
  id,
  ...params
}: ToggleTagActiveParams): Action {
  return {
    endpoint: `/api/admin/content/tags/${id}`,
    method: 'PATCH',
    body: params,
  };
}

export function toggleTagLandingAction(params: ITag): Action {
  return {
    endpoint: `/api/admin/content/tags/${params.id}`,
    method: 'PATCH',
    body: params,
  };
}

export function deleteTagAction({ id }: RemoveTagParam): Action {
  return {
    endpoint: `/api/admin/content/tags/${id}`,
    method: 'DELETE',
  };
}

export function replaceAndDestroyAction({
  id,
  replacer_id,
}: ReplaceAndDestroyTagParam): Action {
  return {
    endpoint: `/api/admin/content/tags/${id}/replace_and_destroy`,
    method: 'POST',
    body: {
      replacer_id,
    },
  };
}

export function createTagAction(tag: ITagRequest): Action {
  return {
    endpoint: '/api/admin/content/tags',
    method: 'POST',
    body: {
      site_id: null,
      tag,
    },
  };
}

export function updateTagAction(tag: ITagRequest): Action {
  return {
    endpoint: `/api/admin/content/tags/${tag.id}`,
    method: 'PATCH',
    body: tag,
  };
}
