import React, { useMemo } from 'react';
import createQueryContext from 'react-fetching-library-context';
import { getTagsAction, updateTagAction } from './tags.actions';
import {
  ITag,
  ITagRequest,
  RemoveTagParam,
  ReplaceAndDestroyTagParam,
} from './tags.types';
import { useTagsHook } from './use-tags';
import { useMutation, useParameterizedQuery } from 'react-fetching-library';
import { useSearchParams } from 'hooks/use-search-params';

export const {
  useQuery: useTags,
  useQueryContext: useTagsContext,
  Provider: TagsProvider,
} = createQueryContext<{
  count: number;
  tags: ITag[];
}>(getTagsAction);

export function useVideoCategories() {
  return useParameterizedQuery(getTagsAction);
}

export function useUpdateTag() {
  const updateTag = useMutation(updateTagAction);
  const [searchParams] = useSearchParams();
  const tags = useTagsContext();

  return async function (tag: ITagRequest) {
    const { error, payload } = await updateTag.mutate(tag);
    return tags.query(searchParams);
  };
}

export function useTagsToSelect() {
  const { payload } = useTagsContext();

  return useMemo(
    () =>
      payload?.tags.map(({ id, name }) => ({
        label: name,
        value: id.toString(),
      })) || [],
    [payload]
  );
}

export const TagActionsContext = React.createContext({
  removeTagHandle: (params: RemoveTagParam) => {},
  replaceAndDestroyTagHandle: (params: ReplaceAndDestroyTagParam) => {},
  updateTagsListHandle: (tag?: ITag) => {},
});

export default useTagsHook;
