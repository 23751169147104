import { Action } from 'react-fetching-library';
import createQueryContext from 'react-fetching-library-context';

interface Timezones {
  time_zones: string[];
}

export const timezonesEndpoint = `/api/time_zones`;

function getTimezonesAction(): Action {
  return {
    method: 'GET',
    endpoint: timezonesEndpoint,
  };
}

export const {
  useQueryContext: useTimezonesContext,
  Provider: TimezonesProvider,
} = createQueryContext<Timezones>(getTimezonesAction);
