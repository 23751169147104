import React from 'react';

import * as ads from 'routes/ads';
import * as content from 'routes/content';
import * as settings from 'routes/settings';
import * as statistics from 'routes/statistics';
import * as tos from 'routes/tos';
import * as tou from 'routes/tou';
import * as users from 'routes/users';
import * as planning from 'routes/planning-section';
import * as newsletters from 'routes/newsletters';

import {
  ClientDeskElement,
  ClientDeskLiveAdminElement,
  NewsRoomElement,
  NewsRoomLiveAdminElement,
  OutputEditorElement,
  PlanningSection,
  SeoElement,
  SettingsElement,
  SuperAdminElement,
} from 'constants/accesses';
import config from 'config';

const { PROJECT_LOCALE, SWITCH } = config;

type Config = {
  title: string;
  link: string;
  path?: string;
  AccessWrapper?: typeof NewsRoomElement;
};

export type HeaderConfig = ({
  children?: Config[];
} & Config)[];

let menuArray = [
  {
    title: 'Content',
    link: content.RootContentRoute.getLink(),
    children: [
      {
        title: 'Videos',
        link: content.VideosRoute.getLink(),
      },
      {
        title: 'Live Events',
        link: content.LiveEventsRoute.getLink(),
        AccessWrapper: props => (
          <NewsRoomLiveAdminElement allowList={['seo_admin']} {...props} />
        ),
      },
      {
        title: 'Tags',
        link: content.TagsRoute.getLink({ locale: PROJECT_LOCALE }),
        AccessWrapper: props => (
          <NewsRoomElement allowList={['seo_admin']} {...props} />
        ),
      },
      {
        title: 'Tags On Dashboard',
        link: content.TagsOnDashboardRoute.getLink({
          locale: PROJECT_LOCALE,
        }),

        AccessWrapper: props => (
          <NewsRoomElement allowList={['seo_admin']} {...props} />
        ),
      },
      {
        title: 'FTP Videos',
        link: content.FtpVideosRoute.getLink(),
      },
    ],
  },
  {
    title: 'Customers',
    link: users.RootUsersRoute.getLink(),
    AccessWrapper: ClientDeskElement,
    children: [
      {
        title: 'New',
        link: `${users.UsersRoute.getLink({
          type: users.UsersRouteTypes.NEW,
        })}`,
      },
      {
        title: 'Active',
        link: `${users.UsersRoute.getLink({
          type: users.UsersRouteTypes.ACTIVE,
        })}`,
      },
      {
        title: 'Deactivated',
        link: `${users.UsersRoute.getLink({
          type: users.UsersRouteTypes.DEACTIVATED,
        })}`,
      },
      {
        title: 'Unconfirmed',
        link: `${users.UsersRoute.getLink({
          type: users.UsersRouteTypes.UNCONFIRMED,
        })}`,
      },
      {
        title: 'Admins',
        AccessWrapper: SuperAdminElement,
        link: `${users.UsersRoute.getLink({
          type: users.UsersRouteTypes.ADMINS,
        })}`,
      },
    ],
  },
  {
    title: 'Settings',
    link: settings.RootSettingsRoute.getLink(),
    AccessWrapper: SettingsElement,
    children: [
      {
        title: 'General Settings',
        link: settings.GeneralSettingsRoute.getLink(),
        AccessWrapper: SuperAdminElement,
      },
      {
        title: 'Transcoders',
        link: settings.TranscodersSettingsRoute.getLink(),
        AccessWrapper: SuperAdminElement,
      },
      {
        title: 'Email Service',
        link: settings.EmailServiceRoute.getLink(),
        AccessWrapper: SuperAdminElement,
      },
      {
        title: 'Countries',
        link: settings.CountriesSettingsRoute.getLink(),
        AccessWrapper: SuperAdminElement,
      },
      {
        title: 'Seo meta tags',
        link: settings.SettingsSeoMetaTagsRoute.getLink(),
        AccessWrapper: SeoElement,
      },
      {
        title: 'Release notes',
        link: settings.SettingsReleaseNotesRoute.getLink(),
        AccessWrapper: SuperAdminElement,
      },
    ],
  },
  {
    title: 'Alerts and AD',
    path: ads.RootADsRoute.getLink(),
    link: ads.BannersRoute.getLink({ locale: PROJECT_LOCALE }),
    AccessWrapper: OutputEditorElement,
    children: [
      {
        title: 'Banners',

        link: ads.BannersRoute.getLink({
          locale: PROJECT_LOCALE,
        }),
        AccessWrapper: OutputEditorElement,
      },
      {
        title: 'Alerts',
        link: ads.AlertsRoute.getLink({
          locale: PROJECT_LOCALE,
        }),
        AccessWrapper: OutputEditorElement,
      },
    ],
  },
  {
    title: 'Statistics',
    link: statistics.RootStatisticsRoute.getLink(),
    AccessWrapper: ClientDeskLiveAdminElement,
    children: [
      {
        title: 'Target Logs',
        link: statistics.TargetLogsRoute.getLink(),
      },
      {
        title: 'Pass Statistics',
        link: statistics.PassStatisticsRoute.getLink(),
        AccessWrapper: ClientDeskElement,
      },
      {
        title: 'Redash Dashboard',
        link: statistics.RedashStatisticRoute.getLink(),
        AccessWrapper: ClientDeskElement,
      },
    ],
  },
  {
    title: SWITCH ? 'Terms of Use' : 'Terms of Service',
    path: SWITCH ? tou.ToURootRoute.getLink() : tos.ToSRootRoute.getLink(),
    link: SWITCH
      ? tou.ToUFormRoute.getLink({ locale: PROJECT_LOCALE, accessType: 'tv' })
      : tos.ToSFormRoute.getLink({ locale: PROJECT_LOCALE, accessType: 'tv' }),
    AccessWrapper: SuperAdminElement,
  },
  {
    title: 'Planning section',
    path: planning.PlanningSectionRoute.getLink(),
    link: planning.PlanningSectionFormRoute.getLink({
      locale: PROJECT_LOCALE,
    }),
    AccessWrapper: PlanningSection,
  },
] as HeaderConfig;

if (!SWITCH) {
  menuArray.push({
    title: 'Newsletters',
    path: newsletters.NewslettersRoute.getLink(),
    link: newsletters.NewslettersRoute.getLink({ type: 'daily' }),
    AccessWrapper: ClientDeskElement,
    children: [
      {
        title: 'Daily',
        link: newsletters.NewslettersRoute.getLink({ type: 'daily' }),
      },
      {
        title: 'Live',
        link: newsletters.NewslettersRoute.getLink({ type: 'live' }),
      },
      {
        title: 'Coverage',
        link: newsletters.NewslettersRoute.getLink({
          type: 'coverage',
        }),
      },
    ],
  });
}

export default menuArray;
