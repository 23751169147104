import API from 'api';
import { Action } from 'react-fetching-library';
import { ICountry } from './countries.types';
import createQueryContext from 'react-fetching-library-context';

export const getAllCountriesAPI = new API({
  action(): Action {
    return {
      method: 'GET',
      endpoint: '/api/countries.json',
    };
  },
  defaultPayload: [],
  preparePayload: (payload: any): ICountry[] => payload,
});

export const getAllCountries = (): Action => {
  return {
    method: 'GET',
    endpoint: '/api/countries.json',
  };
};

export const {
  useQueryContext: useAllCountriesContext,
  Provider: AllCountriesProvider,
  useQuery: useAllCountriesQuery,
} = createQueryContext<ICountry[]>(getAllCountries);
