import { Action } from 'react-fetching-library';
import createQueryContext from 'react-fetching-library-context';

export function getCountries(): Action {
  return {
    endpoint: '/api/admin/content/countries',
    method: 'GET',
  };
}

interface Country {
  ISO: string;
  id: number;
  name: string;
  name_on_avid: string;
}

export const {
  useQueryContext: useCountriesContext,
  Provider: CountriesProvider,
  useQuery: useCountriesQuery,
} = createQueryContext<Country[]>(getCountries);
