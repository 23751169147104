import React from 'react';

function combineProviders(providers = [], children: any) {
  const provider = providers.shift() || (() => null);
  const nextChildren: any = providers.length
    ? combineProviders(providers, children)
    : children;
  let type: React.FunctionComponent;
  let props = {};

  if (Array.isArray(provider)) {
    type = provider[0];
    props = provider[1];
  } else {
    type = provider;
  }

  return React.createElement(type, props, nextChildren);
}

export default function CombineProviders({ providers, children }: any) {
  return combineProviders(providers, children);
}
