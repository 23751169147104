import clsx from 'clsx';
import { HeaderMenuProps } from 'components/components';
import { ClickOutside } from 'hooks/outside-click';
import React from 'react';
import { Link } from 'react-router-dom';
import { useCurrentUser } from '../../../api/current_user';

export default function HeaderMenu(props: HeaderMenuProps) {
  return (
    <div className="fsheader__menu fsheader-menu">
      <HeaderDropdown active={props.active} menu={props.menu} />
    </div>
  );
}

function HeaderDropdown({ menu = [], active }: HeaderMenuProps) {
  const [open, setOpen] = React.useState(false);

  function toggleMenu() {
    setOpen(!open);
  }

  const { current_user } = useCurrentUser().payload || {};

  return (
    <ClickOutside onClick={() => setOpen(false)}>
      <div className="fsheader-menu__drop _init" onClick={toggleMenu}>
        <button
          type="button"
          className={clsx('fsheader-menu__droplink', {
            _opened: open,
          })}
        >
          {active ? active.title : 'Not found'}
        </button>
        <div
          className="fsheader-menu__dropbody"
          style={{ display: open ? 'block' : 'none' }}
        >
          {menu.map(({ title, link, AccessWrapper = React.Fragment }) => {
            if (
              title === 'Content' &&
              current_user?.admin_group === 'live_admin'
            ) {
              link = '/content/live-events/all';
            }

            return (
              <AccessWrapper key={link}>
                <div key={title} className="fsheader-menu__dropitem">
                  <Link to={link} className="fsheader-menu__dropgo">
                    {title}
                  </Link>
                </div>
              </AccessWrapper>
            );
          })}
        </div>
      </div>
    </ClickOutside>
  );
}
