import React, { useCallback } from 'react';
import clsx from 'clsx';
import GoBackLink from 'components/go-back-link';
import Notifications from 'components/notifications/notifications';
import { matchPath, NavLink, useHistory, useLocation } from 'react-router-dom';
import { VideosRoute } from 'routes/content';
import { RootSettingsRoute } from 'routes/settings';
import mainMenu from './config';
import HeaderMenu from './header-menu';
import { useCurrentUser, useSignOut } from 'api/current_user';
import { SignInRoute } from 'routes/users';
import {
  useFilterHeaderDropDown,
  useFilterHeader,
} from 'hooks/use-filter-header';
import config from 'config';

const { MODE, SWITCH, isProd } = config;

export default function Header() {
  const location = useLocation();
  const history = useHistory();
  const currentMenuItem = mainMenu.find((item) =>
    matchPath(location.pathname, {
      path: item.path || item.link,
      exact: false,
    })
  );
  const wide = currentMenuItem?.link === RootSettingsRoute.getLink();

  const current_user = useCurrentUser().payload?.current_user;
  const isAuthorized = Number.isInteger(current_user?.id);
  const first_name = current_user?.first_name;
  const last_name = current_user?.last_name;
  const admin_group = current_user?.admin_group;

  const headerDropDownMenu = useFilterHeaderDropDown(admin_group);
  const headerMainMenu = useFilterHeader(admin_group);

  const signOutAPI = useSignOut();
  const signOutHandler = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      signOutAPI();
      history.push(SignInRoute.getLink());
    },
    []
  );

  return (
    <>
      <header className="fsheader">
        <div className="fsheader__style" />
        <div
          className={clsx('fsheader__main', {
            'fsheader__main--invisible': wide,
          })}
        >
          {!wide && (
            <>
              <div className="fsheader__logo fsheader-logo">
                <NavLink
                  to={VideosRoute.getLink()}
                  className="fsheader-logo__link"
                >
                  {SWITCH ? (
                    <span className="fsheader-logo__img_v" />
                  ) : (
                    <>
                      <span className="fsheader-logo__img" />
                      <span className="fsheader-logo__cms">cms</span>
                    </>
                  )}
                  {!isProd && <div className="fsheader-mode">{MODE}</div>}
                </NavLink>
              </div>

              {isAuthorized && (
                <HeaderMenu
                  active={currentMenuItem}
                  menu={headerDropDownMenu}
                />
              )}
            </>
          )}
        </div>
        {wide && <GoBackLink className="fsheader__back" />}

        <div
          className={clsx('fsheader__panel', {
            'fsheader__panel--wide': wide,
          })}
        >
          <div className="fsheader__tabs fsheader-tabs">
            {(headerMainMenu || []).map(
              ({ link, title, AccessWrapper = React.Fragment }) => (
                <AccessWrapper key={link}>
                  <div className="fsheader-tabs__item">
                    <NavLink
                      to={link}
                      className="fsheader-tabs__link"
                      activeClassName="_active"
                      // isActive={(match, location) => {
                      //     console.log(match, location, item);
                      //     return false;
                      // }}
                    >
                      {title}
                    </NavLink>
                  </div>
                </AccessWrapper>
              )
            )}
          </div>
          <div className="fsheader__controls fsheader-controls">
            {/* <div className="fsheader-controls__item _active">
                        <a
                            href="#"
                            className="fsheader-controls__link fsheader-controls__link--settings"
                        />
                    </div>
                    <div className="fsheader-controls__item">
                        <a
                            href="#"
                            className="fsheader-controls__link fsheader-controls__link--allerts"
                        />
                    </div> */}
            {isAuthorized ? (
              <>
                <NavLink to={''} className="fsheader-tabs__link">
                  {first_name} {last_name}
                </NavLink>

                <div className="fsheader-controls__item">
                  <a
                    href="#"
                    className="fsheader-controls__link fsheader-controls__link--exit"
                    onClick={signOutHandler}
                  />
                </div>
              </>
            ) : (
              <NavLink
                to={SignInRoute.getLink()}
                className="fsheader-tabs__link"
                style={{ marginRight: '20px' }}
              >
                Sign in
              </NavLink>
            )}
          </div>
        </div>
      </header>
      <Notifications />
    </>
  );
}
