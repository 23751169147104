import React from 'react';
import { useHistory } from 'react-router-dom';

interface IGOBackLinkProps {
  readonly className?: string;
  readonly url?: string | null;
  readonly onClick?: () => void;
}

export default function GoBackLink(props: IGOBackLinkProps) {
  const nav = useHistory();

  function onClick(ev: React.SyntheticEvent) {
    ev.preventDefault();

    if (props.url !== null) {
      if (props.url) {
        const url = props.url.includes('?') ? props.url : `${props.url}`;
        nav.push(url);
      } else {
        nav.push(process.env.PUBLIC_URL);
      }
    }

    props.onClick?.();
  }

  return <a href="#root" className={props.className} onClick={onClick} />;
}
