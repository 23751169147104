import { NotifyAppType } from 'components/types';
import React, { useContext, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { SlideDown } from 'react-slidedown';
import NotifyAppContext from './notifications-context';

const NotificationsItem: React.FC<NotifyAppType> = ({
  title,
  type,
  link,
  id,
}) => {
  let timerID: React.MutableRefObject<ReturnType<typeof setTimeout>> = useRef(
    null as unknown as ReturnType<typeof setTimeout>
  );

  const { closeNotifyHandle } = useContext(NotifyAppContext);

  useEffect(() => {
    timerID.current = setTimeout(() => {
      closeNotifyHandle(id);
    }, 3000);
    return () => {
      clearTimeout(timerID.current);
    };
  }, []);

  return (
    <SlideDown className={'my-dropdown-slidedown'}>
      <div
        className={`notification-message notification-message--${type}`}
        style={{
          display: 'block',
        }}
      >
        <div className="notification-message-inner">
          <span className={`notification-message__${type}`} />
          <span className="notification-message__text">{title}</span>
          {link && (
            <Link to={link.to} className="notification-message__link">
              {link.text}
            </Link>
          )}
        </div>
        <button
          type="button"
          className="notification-message__close"
          onClick={() => closeNotifyHandle(id)}
        />
      </div>
    </SlideDown>
  );
};
export default NotificationsItem;
