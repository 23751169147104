import { Action } from 'react-fetching-library';
import createQueryContext from 'react-fetching-library-context';

export function getRegions(): Action {
  return {
    endpoint: '/api/admin/content/regions',
    method: 'GET',
  };
}

interface IRegion {
  id: number;
  name: string;
}

export const { useQueryContext: useRegionsContext, Provider: RegionsProvider } =
  createQueryContext<IRegion[]>(getRegions);
