import { useCurrentUser } from 'api/current_user';
import React, { Fragment } from 'react';

export type AdminGroups =
  | 'super_admin'
  | 'news_room'
  | 'client_desk'
  | 'live_admin'
  | 'translator'
  | 'output_editor'
  | 'seo_admin'
  | null;

interface IAvailable {
  invert?: boolean;
  children: any;
  allowList?: AdminGroups[];
}

export default function checkAccess(
  roles: AdminGroups[],
  invert: boolean = false
) {
  return function (props: IAvailable) {
    const { current_user } = useCurrentUser().payload || {};
    const check = (list: typeof roles = []) =>
      list.some((role) => current_user?.admin_group === role);

    if (!check(props.allowList)) {
      const noAdminGroups = !current_user?.admin_group;
      const isAdmin = current_user?.user_role === 'admin';

      const notAvailable = noAdminGroups || !isAdmin || !check(roles);

      if (invert || props.invert) {
        if (!notAvailable) {
          return null;
        }
      } else {
        if (notAvailable) {
          return null;
        }
      }
    }

    return <Fragment children={props.children} />;
  };
}
