import React, { useRef, useEffect, RefObject, FC } from 'react';
import { getScrollBarWidth } from 'helpers/elements';

const useOutsideClick = (
  ref: RefObject<HTMLElement>,
  handler: (event: MouseEvent | TouchEvent) => void
) => {
  useEffect(() => {
    const clickedOnScrollbar = (mouseX: number = 0) => {
      let width = document.body.offsetWidth;
      const style = getComputedStyle(document.body);
      const barWidth = getScrollBarWidth();

      width += parseInt(style.marginLeft) + parseInt(style.marginRight);

      if (width - barWidth <= mouseX) {
        return true;
      }
    };

    const listener = (event: MouseEvent | TouchEvent) => {
      if (
        !ref.current ||
        ref.current.contains(event.target as Node) ||
        (event instanceof MouseEvent && clickedOnScrollbar(event.clientX))
      ) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

interface ClickOutsideProps {
  onClick: () => void;
  children: JSX.Element | JSX.Element[];
  className?: string;
  style?: React.CSSProperties;
}

export const ClickOutside: FC<ClickOutsideProps> = ({
  onClick,
  style,
  className,
  children,
}) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  useOutsideClick(wrapperRef, onClick);

  return (
    <div ref={wrapperRef} className={className} style={style}>
      {children}
    </div>
  );
};
