import { AdminGroups } from '../hooks/check-access';
import mainMenu from 'components/layout/header/config';
import { matchPath, useLocation } from 'react-router-dom';

export const useFilterHeaderDropDown = (role?: AdminGroups) => {
  if (!role) return mainMenu;

  switch (role) {
    case 'live_admin':
    case 'news_room':
      return mainMenu.filter((item) => item.title !== 'Settings');
    default:
      return mainMenu;
  }
};

export const useFilterHeader = (role?: AdminGroups) => {
  const location = useLocation();
  if (!role) return mainMenu;

  const currentMenuItem = mainMenu.find((item) =>
    matchPath(location.pathname, {
      path: item.path || item.link,
      exact: false,
    })
  );

  if (!currentMenuItem?.children) return [];

  switch (role) {
    case 'live_admin':
      return currentMenuItem.children.filter(
        (item) => !['Videos', 'FTP Videos'].includes(item.title)
      );
    case 'news_room':
      return currentMenuItem.children.filter(
        (item) => !['FTP Videos'].includes(item.title)
      );
    default:
      return currentMenuItem.children;
  }
};
