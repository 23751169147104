import React, {
  ReactNode,
  createContext,
  useState,
  ReactElement,
  useCallback,
} from 'react';
import { IUpdateLiveEvent } from 'api/admin/content/live/events/events.types';
import { initLive } from 'pages/content/live-events/lives-view-item/new-live';
import { DateTime } from 'luxon';

interface NewLiveEventProviderProps {
  children?: ReactNode;
}

interface Context {
  live: IUpdateLiveEvent | null;
  setNewLiveEventHandle: (live: IUpdateLiveEvent | null) => void;
  isLiveEventUpdated: boolean;
  handleLiveRoute: (route: string) => void;
  liveRoute?: string;
}

const initialContextValue: Context = {
  live: null,
  setNewLiveEventHandle: () => {},
  isLiveEventUpdated: false,
  handleLiveRoute: () => {},
};

const NewLiveEventContext = createContext<Context>(initialContextValue);

const NewLiveEventProvider: React.FC<NewLiveEventProviderProps> = ({
  children,
}): ReactElement => {
  const [live, setLive] = useState<IUpdateLiveEvent | null>({
    ...initLive,
    start_at: String(DateTime.local().plus({ minutes: 30 }).toUTC()),
  });
  const [isLiveEventUpdated, setIsLiveEventUpdated] = useState<boolean>(false);
  const [liveRoute, setLiveRoute] = useState<string | undefined>(undefined);

  const setNewLiveEventHandle = useCallback(
    (newLiveEvent: IUpdateLiveEvent | null) => {
      setLive(
        newLiveEvent
          ? { ...live, ...newLiveEvent }
          : {
              ...initLive,
              start_at: String(DateTime.local().plus({ minutes: 30 }).toUTC()),
            }
      );
      setIsLiveEventUpdated(newLiveEvent !== null);
    },
    [live]
  );

  const handleLiveRoute = (route: string) => setLiveRoute(route);

  return (
    <NewLiveEventContext.Provider
      value={{
        live,
        setNewLiveEventHandle,
        isLiveEventUpdated,
        handleLiveRoute,
        liveRoute,
      }}
    >
      {children}
    </NewLiveEventContext.Provider>
  );
};

export { NewLiveEventProvider, NewLiveEventContext };
