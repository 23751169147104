import route from 'hooks/route';
import { planningSectionConfig } from '../constants/accesses';
import React from 'react';

export const PlanningSectionRoute = route({
  path: '/planning-section',
  component: React.lazy(() => import('pages/planning-section/index')),
  accessConfig: planningSectionConfig,
});

export const PlanningSectionFormRoute = route<{ locale: string }>({
  path: '/planning-section/:locale',
  component: React.lazy(() => import('pages/planning-section/planning-form')),
  accessConfig: planningSectionConfig,
});
