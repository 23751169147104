import qs from 'qs';
import {
  Action,
  createCache,
  createClient,
  QueryResponse,
  RequestInterceptor,
} from 'react-fetching-library';
import config from 'config';
import { CurrentUserEndpoint } from './current_user/current_user';
import { UsersRoutePath } from 'routes/users';
import { localesEndpoint } from './locales';
import { timezonesEndpoint } from './timezones';
import { isAuthorized } from 'helpers/state';

const cache = createCache<QueryResponse>(
  (action: Action) => {
    return action.method === 'GET';
  },
  (response: QueryResponse & { timestamp: number }) => {
    return new Date().getTime() - response.timestamp < 10000;
  }
);

const requestHostInterceptor: (host: string) => RequestInterceptor =
  host => () => async action => {
    const isAbsoluteUrl =
      action.endpoint.startsWith('http://') ||
      action.endpoint.startsWith('https://');

    return {
      ...(isAbsoluteUrl ? {} : { credentials: 'include' }),
      ...action,
      endpoint: isAbsoluteUrl
        ? action.endpoint
        : `${host}${action.endpoint}${
            action.config?.search
              ? qs.stringify(action.config.search, { addQueryPrefix: true })
              : ''
          }`,
    };
  };

// const responsePayloadInterceptor = () => async (
//     action: Action,
//     response: any
// ) => {
//     const { extractContent = (p: any) => p } = action.config || {};
//     let payload = extractContent(response.payload);
//     return {
//         ...response,
//         payload,
//     };
// };

type CustomFetchInit = Partial<Action> & RequestInit;

const doesUrlNeedAuthorization = (url: string) => {
  const urlPathname = url.split('?')[0];

  return (
    !urlPathname.endsWith(CurrentUserEndpoint.currentUser) &&
    !urlPathname.endsWith(CurrentUserEndpoint.adminVerify) &&
    !urlPathname.endsWith(CurrentUserEndpoint.signOut) &&
    !urlPathname.endsWith(CurrentUserEndpoint.signIn) &&
    !urlPathname.endsWith(CurrentUserEndpoint.adminVerify) &&
    !urlPathname.endsWith(CurrentUserEndpoint.resetPassword) &&
    !urlPathname.endsWith(localesEndpoint) &&
    !urlPathname.endsWith(timezonesEndpoint)
  );
};

const customFetch: (
  input: RequestInfo,
  init?: CustomFetchInit
) => Promise<Response> = async (input, init) => {
  const url = typeof input === 'string' ? input : input.url;

  if (
    !isAuthorized() ||
    window.location.pathname.endsWith(UsersRoutePath.signin) ||
    window.location.pathname.endsWith(UsersRoutePath.resetPassword)
  ) {
    if (doesUrlNeedAuthorization(url)) {
      return Promise.resolve(
        new Response(JSON.stringify({ message: 'Unauthorized' }), {
          status: 401,
          headers: { 'Content-Type': 'application/json' },
        })
      );
    }
  }

  return fetch(input, init);
};

export default createClient({
  requestInterceptors: [requestHostInterceptor(config.API_HOST)],
  responseInterceptors: [],
  fetch: customFetch,
  // cacheProvider: cache,
});
