import { NotifyAppType } from 'components/types';
import React from 'react';

const NotifyAppContext = React.createContext<{
  list: NotifyAppType[];
  showNotifyHandle: (config: Omit<NotifyAppType, 'id'>) => void;
  closeNotifyHandle: (id: number) => void;
}>({
  list: [],
  showNotifyHandle: (config) => {},
  closeNotifyHandle: (id) => {},
});

export default NotifyAppContext;
