import route from 'hooks/route';
import { clientDeskAdminConfig } from '../constants/accesses';
import React from 'react';

export const RootStatisticsRoute = route({
  path: '/statistics',
  component: React.lazy(() => import('pages/statistics')),
  accessConfig: clientDeskAdminConfig,
});

export const TargetLogsRoute = route({
  path: '/statistics/target',
  component: React.lazy(() => import('pages/statistics/target')),
  accessConfig: clientDeskAdminConfig,
});

export const PassStatisticsRoute = route({
  path: '/statistics/purchases-and-downloads',
  component: React.lazy(() => import('pages/statistics/pass-statistics')),
  accessConfig: clientDeskAdminConfig,
});

export const RedashStatisticRoute = route({
  path: '/statistics/redash',
  component: React.lazy(() => import('pages/statistics/redash')),
  accessConfig: clientDeskAdminConfig,
});
