import arrayMove from 'array-move';
import { useSearchParams } from 'hooks/use-search-params';
import { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { useMutation, useParameterizedQuery } from 'react-fetching-library';
import { useParams } from 'react-router-dom';
import { NotifyAppContext } from 'components/notifications';
import { useTagsContext } from '.';
import {
  deleteTagAction,
  getDashboardTagsAction,
  getTagsAction,
  replaceAndDestroyAction,
  updatePositionAction,
} from './tags.actions';
import {
  ITag,
  RemoveTagParam,
  ReplaceAndDestroyTagParam,
  TableMoveFuncParams,
} from './tags.types';

const TAGS_PER_PAGE = 10;

export function useTagsHook(isDashboard?: boolean) {
  const [init, setInit] = useState(false);
  const [tags, setTags] = useState<ITag[]>([]);
  const [isLoadMore, setLoadMoreStatus] = useState(false);
  const tagsAPI = useParameterizedQuery(
    isDashboard ? getDashboardTagsAction : getTagsAction
  );
  const routerParams = useParams() as { locale: string };
  const [searchParams, setSearchParams] = useSearchParams() as any;
  const count = (tagsAPI.payload || { count: 0 }).count;
  const allPagesCount = useMemo(() => Math.ceil(count / 10), [count]);
  const updatePositionAPI = useMutation(updatePositionAction);
  const deleteAPI = useMutation(deleteTagAction);
  const replaceAndDestroyAPI = useMutation(replaceAndDestroyAction);
  const tagsContext = useTagsContext();
  const { showNotifyHandle } = useContext(NotifyAppContext);

  useEffect(() => {
    if (!tagsAPI.error && tagsAPI.payload) {
      setTags(prev => {
        return isLoadMore
          ? [...prev, ...tagsAPI.payload!.tags]
          : tagsAPI.payload!.tags;
      });
      setLoadMoreStatus(false);
      !init && setInit(true);
    }
  }, [tagsAPI.payload]);

  useEffect(() => {
    tagsContext.query({}).then(({ error }) => {
      if (error) {
        showNotifyHandle({
          type: 'error',
          title: 'Something went wrong',
        });
      }
    });
  }, []);

  useEffect(() => {
    const params = {
      per_page: TAGS_PER_PAGE,
      ...routerParams,
      ...searchParams,
    };
    tagsAPI.query(params);
  }, [searchParams]);

  useEffect(() => {
    if (isDashboard) {
      tagsAPI.query(routerParams);
    } else {
      setSearchParams({
        per_page: TAGS_PER_PAGE,
        page: 1,
        ...searchParams,
      });
    }
  }, [routerParams.locale]);

  const changePageHandle = useCallback(
    (numb: number = 1) => {
      setSearchParams({ ...searchParams, page: numb });
    },
    [searchParams]
  );

  const loadMoreHandle = useCallback(() => {
    setLoadMoreStatus(true);
    setSearchParams({ ...searchParams, page: ++searchParams.page });
  }, [searchParams]);

  const onMoveEndHandle = ({ oldIndex, newIndex }: TableMoveFuncParams) => {
    setTags(tagsPrev => {
      updatePositionAPI.mutate({
        id: tagsPrev[oldIndex].id,
        position: newIndex + 1,
        locale: routerParams.locale,
      });

      return arrayMove(tagsPrev, oldIndex, newIndex);
    });
  };

  const removeTag = useCallback(
    async (params: RemoveTagParam) => {
      await deleteAPI.mutate(params);
      await tagsAPI.query(searchParams);
      await tagsContext.query({});
    },
    [tags]
  );

  const replaceAndDestroyTag = useCallback(
    async (params: ReplaceAndDestroyTagParam) => {
      await replaceAndDestroyAPI.mutate(params);
      await tagsAPI.query(searchParams);
    },
    [tags]
  );

  const updateTagsList = useCallback(
    async (tag?: ITag) => {
      if (tag) {
        setTags(prev => {
          const index = prev.findIndex(({ id }) => tag.id === id);
          prev[index] = tag;
          return [...prev];
        });
      } else {
        const params = {
          per_page: TAGS_PER_PAGE,
          ...routerParams,
          page: 1,
        };
        setSearchParams(params);
        await tagsAPI.query(searchParams);
      }
    },
    [tags]
  );

  return {
    tags,
    notFound: init && tags?.length === 0 && tagsAPI.status === 200,
    allPagesCount,
    changePageHandle,
    loadMoreHandle,
    per_page: TAGS_PER_PAGE,
    count,
    page: Number(searchParams.page),
    isLoadMore,
    onMoveEndHandle,
    removeTag,
    replaceAndDestroyTag,
    updateTagsList,
  };
}
