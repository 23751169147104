import React from 'react';
import route from 'hooks/route';
import { OutputEditorAdminsConfig } from '../constants/accesses';

export interface BannersRouteParams {
  locale: string;
}

export interface BannerRoute extends BannersRouteParams {
  id: string;
}

export const RootADsRoute = route({
  path: '/ads',
  component: React.lazy(() => import('pages/ads/banners')),
  accessConfig: OutputEditorAdminsConfig,
});

export const BannersRoute = route<BannersRouteParams>({
  path: '/ads/banners/:locale',
  component: React.lazy(() => import('pages/ads/banners')),
  accessConfig: OutputEditorAdminsConfig,
});

export const EditBannerRoute = route<BannerRoute>({
  path: '/ads/banners/:locale/:id',
  component: React.lazy(() => import('pages/ads/banners')),
  accessConfig: OutputEditorAdminsConfig,
  exact: true,
});

export const AlertsRoute = route<BannersRouteParams>({
  path: '/ads/alerts/:locale',
  component: React.lazy(() => import('pages/ads/alerts')),
  accessConfig: OutputEditorAdminsConfig,
  exact: true,
});

export interface IAlertRouteParams {
  locale: string;
  id: number;
  type: string;
}

export const AlertsHistoryRoute = route<IAlertRouteParams>({
  path: '/ads/alerts/:locale/:id/:type/history',
  component: React.lazy(() => import('pages/ads/alerts/history')),
  accessConfig: OutputEditorAdminsConfig,
  exact: true,
});

export const BannersHistoryRoute = route<BannerRoute>({
  path: '/ads/banner/:locale/:id/history',
  component: React.lazy(() => import('pages/ads/banners/history')),
  accessConfig: OutputEditorAdminsConfig,
  exact: true,
});
